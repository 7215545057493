.personal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.header {
    font-size: 3rem;
    margin-bottom: 0.5rem;
}

.subheader {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}
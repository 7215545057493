.authorize-container {
    height: calc(100vh-16rem);
    padding-top: 16rem;
    margin: auto;
    width: 24rem;
}

.authorize {
    width: 100%;
    padding: 2rem;
    border: 1px solid #333;
}

.password {
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 1rem;
}

.authorize-button {
    margin-top: 2rem;
    display: block;
    width: 12rem;
    margin-left: 12rem;
    background: #4287f5;
    border: none;
    padding: 0.5rem;
    color: #fafafa;
}
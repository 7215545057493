.employer {
    margin-top: 2rem;
}

.section {
    margin-top: 1rem;
}

.assignment-list {
    margin-top: 2rem;
    padding-left: 1rem;
    border-left: 0.25rem solid #ccc;
}

.achievement {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
}
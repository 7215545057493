body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1,
h2,
h3,
h4 {
    margin: 0;
}

ul {
    list-style: circle;
}

.header-icon {
    margin-right: 0.5rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.italic {
    font-style: italic;
}

.font-weight-500 {
    font-weight: 500;
}

.cv {
    display: grid;
    grid-template-columns: [left] 26rem [right] 1fr;
}

.column-left {
    background: #333;
    color: #fafafa;
    padding: 2rem;
}

.column-right {
    background: #fafafa;
    color: #333;
    padding: 2rem;  
}

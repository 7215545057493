.profile-picture-background {
    width: 100%;
    height: 18rem;
    background: #ccc;
}

.profile-picture {
    height: 18rem;
    display: block;
    margin: auto;
}